import React, { Fragment, useRef, useState } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import './PrevencaoPerdas.scss';
import classes from './SegundoEbook.module.scss'
import book from '../assets/images/ebook2.png';
import logo from '../assets/images/logo_branca.png';

import successIcon from '../assets/images/check.svg';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function SegundoEbook() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [cargo, setCargo] = useState('');

    const [nomeInvalid, setNomeInvalid] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [empresaInvalid, setEmpresaInvalid] = useState(false);
    const [cargoInvalid, setCargoInvalid] = useState(false);

    const [formInvalid, setFormInvalid] = useState(false);
    const [inscrito, setInscrito] = useState(false);

    const nomeRef = useRef(null);
    const emailRef = useRef(null);
    const empresaRef = useRef(null);
    const cargoRef = useRef(null);

    const captchaRef = useRef(null);

    const submitForm = async (e) => {
        e.preventDefault();

        if (!validaCampos()) return;

        const token = await captchaRef.current.executeAsync();
        captchaRef.current.reset();

        const data = {
            nome,
            email,
            empresa,
            cargo,
            id_ebook: 2,
            token,
            titulo: 'Prevenção de perdas: validade e seus desafios',
            filename: 'ebook_validade_e_seus_desafios.pdf'
        };

        axios.post(`${baseURL}/site/inscricoes`, data).then(() => console.log('enviado'));

        nomeRef.current.value = '';
        emailRef.current.value = '';
        empresaRef.current.value = '';
        cargoRef.current.value = '';

        setFormInvalid(false);
        setInscrito(true);
    };

    const validaCampos = () => {
        if (nome === '') setNomeInvalid(true);
        if (email === '') setEmailInvalid(true);
        if (empresa === '') setEmpresaInvalid(true);
        if (cargo === '') setCargoInvalid(true);

        if (nome === '' || email === '' || empresa === '' || cargo === '') {
            setFormInvalid(true);
            return false;
        }

        return true;
    };

    const handlerChangeNome = (event) => {
        setNome(event.target.value);
        setNomeInvalid(false);
    };

    const handlerChangeEmail = (event) => {
        setEmail(event.target.value);
        setEmailInvalid(false);
    };

    const handlerChangeEmpresa = (event) => {
        setEmpresa(event.target.value);
        setEmpresaInvalid(false);
    };

    const handlerChangeCargo = (event) => {
        setCargo(event.target.value);
        setCargoInvalid(false);
    };

    const closeModal = () => {
        setInscrito(false);
    };

    return (
        <div className={classes.wrapper}>
            {inscrito && (
                <Fragment>
                    <div className={classes.overlay} onClick={closeModal} />

                    <div className={classes.overlay_centered}>
                        <div className={classes.card}>
                            <div className={classes.modal}>
                                <img className={classes.success} src={successIcon} alt="icone de sucesso" />

                                <h4>E-book enviado com sucesso!</h4>

                                <p>Você receberá o material no seu e-mail nos próximos minutos.</p>

                                <button type="button" className={classes.success} onClick={closeModal}>
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}

            <div className={classes.content}>
                <div className={classes.content__highlight}>
                    <span>eBook gratuito</span>
                    <h2>Prevenção de perdas: validade e seus desafios</h2>
                </div>

                <img src={book} alt="ebook imagem" />

                <div className={classes.content__text}>
                    <div>
                        <h3>Por que Controlar:</h3>

                        <p>Saiba o porquê da importância da prevenção de perdas por validade.</p>
                    </div>

                    <div>
                        <h3>Custos Indiretos: </h3>

                        <p>Veja os custos "não visíveis" da perda por validade.</p>
                    </div>

                    <div>
                        <h3>Boas Práticas para Prevenção:</h3>

                        <p>Veja algumas ações, direcionadas para loja e administração, para reduzir e controlar suas perdas.</p>
                    </div>

                    <div>
                        <h3>Lançamento de Perdas:</h3>

                        <p>Utilize nossa ferramenta de lançamento, saiba como mensurar o real impacto das perdas na sua empresa.</p>
                    </div>
                </div>
            </div>

            <div className={classes.contato}>
                <div className={classes.contato__descricao}>
                    <p>Preencha o formulário e receba o material em seu e-mail!</p>
                </div>

                <form className={classes.form} onSubmit={submitForm}>
                    <div>
                        <label htmlFor="nome">
                            Nome<span>*</span>
                        </label>
                        <input className={nomeInvalid ? classes.invalid : ''} type="text" name="nome" id="nome" ref={nomeRef} onChange={handlerChangeNome} />
                    </div>

                    <div>
                        <label htmlFor="email">
                            E-mail<span>*</span>
                        </label>
                        <input className={emailInvalid ? classes.invalid : ''} type="email" name="email" id="email" ref={emailRef} onChange={handlerChangeEmail} />
                    </div>

                    <div>
                        <label htmlFor="empresa">
                            Empresa<span>*</span>
                        </label>
                        <input
                            className={empresaInvalid ? classes.invalid : ''}
                            type="text"
                            name="empresa"
                            id="empresa"
                            ref={empresaRef}
                            onChange={handlerChangeEmpresa}
                        />
                    </div>

                    <div>
                        <label htmlFor="cargo">
                            Cargo<span>*</span>
                        </label>
                        <input className={cargoInvalid ? classes.invalid : ''} type="text" name="cargo" id="cargo" ref={cargoRef} onChange={handlerChangeCargo} />
                    </div>

                    {formInvalid && <p className={classes.form__invalid}>Preencha todos os campos obrigatórios</p>}

                    <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} size="invisible" />

                    <button type="submit">Quero me inscrever</button>
                </form>

                <img src={logo} alt="logo da empresa" />
            </div>
        </div>
    );
}
