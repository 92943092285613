import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import PrevencaoPerdas from './pages/PrevencaoPerdas.jsx';
import SegundoEbook from './pages/SegundoEbook.jsx';

export default function App() {
    return (
        <Routes>
            <Route path="/introducao-a-prevencao-de-perdas" element={<PrevencaoPerdas />} />
            <Route path="/validade-e-seus-desafios" element={<SegundoEbook />} />

            <Route path="*" element={<Navigate to="/validade-e-seus-desafios" replace />} />
        </Routes>
    );
}
